import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { 
  Flex, 
  Box, 
  Text, 
  useColorModeValue, 
  VStack, 
  HStack, 
  Link,
  Container
} from '@chakra-ui/react';
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa';

import CheckoutSteps from './components/CheckoutSteps';
import CustomerInformation from './components/CustomerInformation';
import ShippingMethod from './components/ShippingMethod';
import PaymentMethod from './components/PaymentMethod';
import Confirmation from './components/Confirmation';
import Declined from './components/Declined';
import Accepted from './components/Accepted';
import CartSummary from './components/CartSummary';
import ReviewCarousel from './components/ReviewCarousel';

import { CheckoutProvider } from './context/CheckoutContext';
import { PixelProvider } from './context/PixelContext';

import { PaymentNeededCheck, CheckoutCompleteCheck } from './utils/checkoutStatus';
import reviews from './data/reviews';

function MainLayout({ children }) {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const bg = useColorModeValue('gray.50', 'gray.900');
  const color = useColorModeValue('gray.800', 'white');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const hasProductParams = searchParams.has('imageU') && searchParams.has('productN') && searchParams.has('productP');
    const clickId = searchParams.get('clickId'); // Aggiungi questa riga

    // Reindirizza a Google se non ci sono parametri del prodotto e siamo su "/" o "/shipping"
    if (!hasProductParams && (location.pathname === '/' || location.pathname === '/shipping')) {
      window.location.href = 'https://www.google.com';
      return;
    }

    // Reindirizza a /shipping solo se siamo sulla home e abbiamo parametri del prodotto
    if (hasProductParams && location.pathname === '/') {
      const newSearchParams = new URLSearchParams(location.search);
      if (clickId) {
        newSearchParams.append('clickId', clickId);
      }
      navigate('/shipping?' + newSearchParams.toString());
    }
    
  }, [location, navigate]);

  const isAcceptedPage = location.pathname === '/accepted';

  return (
    <Flex direction="column" minH="100vh" bg={bg} color={color}>
      <Container maxW="container.xl" px={[4, 6, 8]} py={[6, 8, 10]} flex="1">
        <Flex direction={{ base: "column", lg: "row" }} gap={8}>
          {/* Cart Summary - always visible, above on mobile */}
          {!isAcceptedPage && (
            <Box width={{ base: "100%", lg: "35%" }} order={{ base: 1, lg: 2 }}>
              <CartSummary />
              <Box display={{ base: "none", lg: "block" }} mt={8}>
                <ReviewCarousel reviews={reviews} />
              </Box>
            </Box>
          )}

          {/* Main checkout area */}
          <Box flex="1" order={{ base: 2, lg: 1 }}>
            {!isAcceptedPage && <CheckoutSteps step={currentStep} mb={6} />}
            {React.Children.map(children, child =>
              React.cloneElement(child, { setCurrentStep })
            )}
          </Box>
        </Flex>
      </Container>

      {/* Footer */}
      <Box as="footer" bg="gray.800" color="white" py={8} px={4}>
        <Container maxW="container.xl">
          <Flex direction={{ base: "column", md: "row" }} justify="space-between" align="center" wrap="wrap">
            <VStack spacing={2} mb={{ base: 4, md: 0 }} align={{ base: "center", md: "flex-start" }}>
              <Text>&copy; {new Date().getFullYear()} Online Shopper. Tutti i diritti riservati.</Text>
            </VStack>

            <VStack spacing={2} mb={{ base: 4, md: 0 }} align={{ base: "center", md: "flex-start" }}>
              <Link href="#" isExternal>Privacy Policy</Link>
              <Link href="#" isExternal>Termini di Servizio</Link>
            </VStack>

            <HStack spacing={4} justify="center">
              <FaCcVisa size="2em" />
              <FaCcMastercard size="2em" />
              <FaCcAmex size="2em" />
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
}

function App() {
  return (
    <CheckoutProvider>
      <PixelProvider>
        <Router>
          <MainLayout>
            <Routes>
              <Route path="/" element={<CustomerInformation />} />
              <Route path="/shipping" element={<ShippingMethod />} />
              <Route 
                path="/payment" 
                element={
                  <PaymentNeededCheck>
                    <PaymentMethod />
                  </PaymentNeededCheck>
                } 
              />
              <Route 
                path="/confirm" 
                element={
                  <CheckoutCompleteCheck>
                    <Confirmation />
                  </CheckoutCompleteCheck>
                } 
              />
              <Route 
                path="/declined" 
                element={
                  <CheckoutCompleteCheck>
                    <Declined />
                  </CheckoutCompleteCheck>
                } 
              />
              <Route path="/accepted" element={<Accepted />} />
            </Routes>
          </MainLayout>
        </Router>
      </PixelProvider>
    </CheckoutProvider>
  );
}

export default App;