import React, { useEffect, useState } from 'react';
import { useCheckoutDispatch } from '../context/CheckoutContext';
import { Text, Button, VStack, Image, useBreakpointValue, Container, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFacebookPixel } from '../hooks/useFacebookPixel';
import { usePixelContext } from '../context/PixelContext';

export default function Accepted() {
  const navigate = useNavigate();
  const dispatch = useCheckoutDispatch();
  const { pixelId } = usePixelContext();
  const { trackPurchase } = useFacebookPixel(pixelId);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const titleSize = useBreakpointValue({ base: 'xl', md: '2xl' });
  const bodySize = useBreakpointValue({ base: 'md', lg: 'lg' });
  const imageSize = useBreakpointValue({ base: '150px', md: '200px' });
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });

  useEffect(() => {
    const storedOrderDetails = localStorage.getItem('lastOrderDetails');
    if (storedOrderDetails) {
      const parsedOrderDetails = JSON.parse(storedOrderDetails);
      setOrderDetails(parsedOrderDetails);
      trackPurchase(parsedOrderDetails);
      dispatch({ type: 'COMPLETE_CHECKOUT' });
    } else {
      // Se non ci sono dati nel localStorage, reindirizza a /shipping
      navigate('/shipping');
    }
    setIsLoading(false);
  }, [navigate, trackPurchase, dispatch]);

  const handleContinueShopping = () => {
    dispatch({ type: 'RESET_CHECKOUT' });
    navigate('/shipping');
  };

  if (isLoading) {
    return (
      <Container centerContent>
        <Spinner size="xl" />
      </Container>
    );
  }

  if (!orderDetails) {
    return null;
  }

  return (
    <Container maxW="container.md" centerContent>
      <VStack spacing={5} align="center" justify="center" p={5}>
        <Image
          src="https://imgs.search.brave.com/AOdRRwo8axPm7scQ-MkPjPWVoE8ujgGMS27OJxFQ_Og/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tYXJr/ZXRwbGFjZS5jYW52/YS5jb20vRUFGQWh4/VWJIZ3MvMi8wLzE2/MDB3L2NhbnZhLXdh/dGVyY29sb3ItdGhh/bmsteW91LWZvci15/b3VyLW9yZGVyLWNh/cmQtWlNraHNzaGhO/alEuanBn"
          boxSize={imageSize}
          alt="Grazie per il tuo acquisto!"
        />
        <Text fontSize={titleSize} fontWeight="bold">
          Grazie per il tuo acquisto!
        </Text>
        <Text fontSize={bodySize} textAlign="center">
          Il tuo ordine è stato ricevuto con successo. Riceverai una email di conferma a breve.
        </Text>
        <Text fontSize={bodySize} textAlign="center">
          Totale ordine: €{orderDetails.total.toFixed(2)}
        </Text>
        <Button
          colorScheme="blue"
          size={buttonSize}
          onClick={handleContinueShopping}
        >
          Continua lo shopping
        </Button>
      </VStack>
    </Container>
  );
}