// Confirmation.js
import React from 'react';
import { useCheckoutState } from '../context/CheckoutContext'; // Assicurati che il percorso sia corretto
import { Button, Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Confirmation() {
  const { customerInfo, shippingMethod, paymentDetails } = useCheckoutState();
  const navigate = useNavigate();

  const handleOrder = () => {
    // Qui invii l'ordine al server, ad esempio utilizzando fetch o axios
    // Assicurati di gestire l'invio in modo sicuro, specialmente per quanto riguarda le informazioni di pagamento
    console.log(customerInfo, shippingMethod, paymentDetails);
    // Dopo l'invio, potresti voler reindirizzare l'utente a una pagina di ringraziamento
    // navigate('/thank-you');
  };

  return (
    <Box>
      <Text fontSize="2xl" mb="4">Conferma Ordine</Text>
      {/* Qui mostrerai i riepilogo delle informazioni raccolte */}
      <Box mb="4">
        <Text fontWeight="bold">Informazioni Cliente:</Text>
        <Text>Email: {customerInfo.email}</Text>
        <Text>Nome: {customerInfo.firstName} {customerInfo.lastName}</Text>
      </Box>
      
      <Box mb="4">
        <Text fontWeight="bold">Metodo di Spedizione:</Text>
        <Text>{shippingMethod.name} - ${shippingMethod.price}</Text>
      </Box>
      
      <Box mb="4">
        <Text fontWeight="bold">Dettagli Pagamento:</Text>
        {/* Mostra solo le ultime 4 cifre della carta di credito per motivi di sicurezza */}
        <Text>Carta di Credito: **** **** **** {paymentDetails.cardNumber.slice(-4)}</Text>
      </Box>
      
      <Button colorScheme="blue" onClick={handleOrder}>
        Piazza Ordine
      </Button>
    </Box>
  );
}
