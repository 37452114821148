// CheckoutSteps.js

import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

const CheckoutSteps = ({ step }) => {
  const stepColor = useColorModeValue('blue.500', 'blue.300');
  const inactiveColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Flex align="center" mb={8}>
      <Box flex="1" bg={step >= 1 ? stepColor : inactiveColor} h="2px" />
      <Text mx={2} color={step >= 1 ? stepColor : inactiveColor}>
        1. Informazioni
      </Text>
      <Box flex="1" bg={step >= 2 ? stepColor : inactiveColor} h="2px" />
      <Text mx={2} color={step >= 2 ? stepColor : inactiveColor}>
        2. Pagamento
      </Text>
      <Box flex="1" bg={step >= 3 ? stepColor : inactiveColor} h="2px" />
      <Text mx={2} color={step >= 3 ? stepColor : inactiveColor}>
        3. Conferma
      </Text>
      <Box flex="1" bg={step >= 4 ? stepColor : inactiveColor} h="2px" />
    </Flex>
  );
};

export default CheckoutSteps;
