import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  VStack,
  HStack,
  Image,
  Text,
  Button,
  Input,
  Divider,
  useColorModeValue,
  Spacer
} from '@chakra-ui/react';
import { useCheckoutState, useCheckoutDispatch } from '../context/CheckoutContext';
import { useFacebookPixel } from '../hooks/useFacebookPixel';

export default function CartSummary() {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems } = useCheckoutState();
  const dispatch = useCheckoutDispatch();
  const initialRenderRef = useRef(true);
  const [hasProcessedUrl, setHasProcessedUrl] = useState(false);
  const [pixelId, setPixelId] = useState(null);

  const { trackAddToCart, trackInitiateCheckout } = useFacebookPixel(pixelId);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }

    if (hasProcessedUrl) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const imageUrl = searchParams.get('imageU');
    const productName = searchParams.get('productN');
    //const productPrice = searchParams.get('productP');
    const productPrice = "10.00";
    const px = searchParams.get('px');

    if (px) {
      setPixelId(px);
    }

    if (imageUrl && productName && productPrice) {
      const product = {
        id: productName,
        name: productName,
        price: parseFloat(productPrice),
        imageUrl: imageUrl,
        quantity: 1,
      };

      dispatch({
        type: 'ADD_TO_CART',
        payload: product,
      });

      if (px) {
        trackAddToCart(product);
      }
    }

    setHasProcessedUrl(true);
  }, [location, dispatch, hasProcessedUrl, trackAddToCart]);

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateShipping = () => {
    return 0.00;
  };

  const handleProceedToCheckout = () => {
    if (pixelId) {
      trackInitiateCheckout(cartItems);
    }
    navigate('/shipping');
  };

  const bg = useColorModeValue('white', 'gray.800');
  const color = useColorModeValue('gray.800', 'white');

  return (
    <VStack
      bg={bg}
      color={color}
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      w="full"
      maxW={'md'}
      align="stretch"
      spacing={5}
    >
      <Text fontSize="2xl" fontWeight="bold" textAlign="center">Il tuo carrello</Text>
      <Divider />
      {cartItems.map((item) => (
        <HStack key={item.id} spacing={4} align="center">
          <Image
            boxSize="75px"
            objectFit="cover"
            src={item.imageUrl}
            alt={item.name}
            borderRadius="md"
          />
          <VStack align="start" flex="1">
            <Text fontWeight="bold">{item.name}</Text>
            <Text fontSize="sm">Quantità: {item.quantity}</Text>
          </VStack>
          <Text fontSize="lg" fontWeight="semibold">
            €{(item.price * item.quantity).toFixed(2)}
          </Text>
        </HStack>
      ))}
      <Divider />
      
      <VStack spacing={2} align="stretch">
        <HStack>
          <Text>Subtotale</Text>
          <Spacer />
          <Text>€{calculateTotal().toFixed(2)}</Text>
        </HStack>
        <HStack>
          <Text>Spedizione</Text>
          <Spacer />
          <Text>€{calculateShipping().toFixed(2)}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Totale</Text>
          <Spacer />
          <Text fontWeight="bold">€{(calculateTotal() + calculateShipping()).toFixed(2)}</Text>
        </HStack>
      </VStack>

      <Input placeholder="Inserisci codice promozionale" size="sm" />
      <Button size="sm" colorScheme="blue">Applica</Button>
    </VStack>
  );
}