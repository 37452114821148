import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Heading,
  VStack,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCheckoutDispatch } from '../context/CheckoutContext';

const CustomerSchema = Yup.object().shape({
  email: Yup.string().email('Email non valida').required('Richiesto'),
  firstName: Yup.string().required('Richiesto'),
  lastName: Yup.string().required('Richiesto'),
});

export default function CustomerInformation() {
  const navigate = useNavigate();
  const dispatch = useCheckoutDispatch();
  const inputBg = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box
      p={8}
      maxWidth="500px"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="md"
      margin="0 auto"
      bg={useColorModeValue('white', 'gray.800')}
    >
      <Text fontSize={{ base: "lg", md: "2xl" }} mb={4} textAlign="center">Informazioni preliminari</Text>
      <Formik
        initialValues={{ email: '', firstName: '', lastName: '' }}
        validationSchema={CustomerSchema}
        onSubmit={(values) => {
          dispatch({ type: 'SET_CUSTOMER_INFO', payload: values });
          navigate('/shipping');
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <VStack spacing={4}>
              <FormControl isInvalid={errors.email && touched.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Field name="email">
                  {({ field }) => (
                    <Input
                      {...field}
                      id="email"
                      type="email"
                      bg={inputBg}
                      _hover={{ bg: 'gray.100' }}
                      _placeholder={{ color: 'gray.500' }}
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.firstName && touched.firstName}>
                <FormLabel htmlFor="firstName">Nome</FormLabel>
                <Field name="firstName">
                  {({ field }) => (
                    <Input
                      {...field}
                      id="firstName"
                      type="text"
                      bg={inputBg}
                      _hover={{ bg: 'gray.100' }}
                      _placeholder={{ color: 'gray.500' }}
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.lastName && touched.lastName}>
                <FormLabel htmlFor="lastName">Cognome</FormLabel>
                <Field name="lastName">
                  {({ field }) => (
                    <Input
                      {...field}
                      id="lastName"
                      type="text"
                      bg={inputBg}
                      _hover={{ bg: 'gray.100' }}
                      _placeholder={{ color: 'gray.500' }}
                    />
                  )}
                </Field>
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                width="full"
                colorScheme="blue"
                isLoading={isSubmitting}
                type="submit"
              >
                Metodo di Spedizione
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}