import { useEffect, useRef, useState } from 'react';

export const useFacebookPixel = (pixelId) => {
  const pixelInitialized = useRef(false);
  const [isPixelBlocked, setIsPixelBlocked] = useState(false);

  useEffect(() => {
    if (!pixelId) return;

    const initializeFbq = () => {
      if (window.fbq) return;
      
      window.fbq = function() {
        window.fbq.callMethod ? 
          window.fbq.callMethod.apply(window.fbq, arguments) : 
          window.fbq.queue.push(arguments);
      };
      window.fbq.push = window.fbq;
      window.fbq.loaded = true;
      window.fbq.version = '2.0';
      window.fbq.queue = [];
    };

    const loadPixelScript = () => {
      return new Promise((resolve, reject) => {
        initializeFbq(); // Inizializza fbq prima di caricare lo script

        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/fbevents.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = () => {
          setIsPixelBlocked(true);
          reject(new Error('Facebook Pixel script failed to load'));
        };
        document.head.appendChild(script);
      });
    };

    const initializePixel = () => {
      if (!window.fbq) return;
      
      window.fbq('init', pixelId);
      window.fbq('track', 'PageView');
      pixelInitialized.current = true;
    };

    const setupPixel = async () => {
      if (!pixelInitialized.current) {
        try {
          await loadPixelScript();
          initializePixel();
        } catch (error) {
          console.error('Failed to initialize Facebook Pixel:', error);
          setIsPixelBlocked(true);
        }
      } else {
        // Se il pixel è già stato inizializzato, ma con un ID diverso, reinizializziamo
        window.fbq('init', pixelId);
      }
    };

    setupPixel();

    return () => {
      pixelInitialized.current = false;
    };
  }, [pixelId]);

  const trackEvent = (eventName, eventParams = {}) => {
    if (window.fbq && pixelId && !isPixelBlocked) {
      window.fbq('track', eventName, eventParams);
    } else if (isPixelBlocked) {
      console.log('Facebook Pixel is blocked. Event not tracked:', eventName, eventParams);
    } else {
      console.warn('Facebook Pixel not initialized or Pixel ID missing');
    }
  };

  const trackViewContent = (product) => {
    trackEvent('ViewContent', {
      content_name: product.name,
      content_ids: [product.id],
      content_type: 'product',
      value: product.price,
      currency: 'EUR'
    });
  };

  const trackAddToCart = (product) => {
    trackEvent('AddToCart', {
      content_name: product.name,
      content_ids: [product.id],
      content_type: 'product',
      value: product.price,
      currency: 'EUR'
    });
  };

  const trackInitiateCheckout = (cart) => {
    trackEvent('InitiateCheckout', {
      content_ids: cart.map(item => item.id),
      content_type: 'product',
      value: cart.reduce((total, item) => total + item.price * item.quantity, 0),
      num_items: cart.reduce((total, item) => total + item.quantity, 0),
      currency: 'EUR'
    });
  };

  const trackAddPaymentInfo = () => {
    trackEvent('AddPaymentInfo');
  };

  const trackPurchase = (purchaseData) => {
    trackEvent('Purchase', {
      content_ids: purchaseData.products.map(item => item.id),
      content_type: 'product',
      value: purchaseData.total,
      num_items: purchaseData.products.reduce((total, item) => total + item.quantity, 0),
      currency: 'EUR',
      transaction_id: purchaseData.transactionId
    });
  };

  return { 
    trackEvent, 
    trackViewContent, 
    trackAddToCart, 
    trackInitiateCheckout, 
    trackAddPaymentInfo, 
    trackPurchase,
    isPixelBlocked
  };
};