import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useCheckoutState } from '../context/CheckoutContext';

export const PaymentNeededCheck = ({ children }) => {
  const { cartItems, customerInfo, shippingMethod } = useCheckoutState();
  
  const isNeededPayment = 
    cartItems.length > 0 && 
    Object.keys(customerInfo).length > 0 && 
    Object.keys(shippingMethod).length > 0;

  return isNeededPayment ? children : <Navigate to="/shipping" replace />;
};

export const CheckoutCompleteCheck = ({ children }) => {
  const { isCheckoutComplete } = useCheckoutState();
  const location = useLocation();

  // Permetti l'accesso alla pagina /accepted anche se isCheckoutComplete è false
  if (location.pathname === '/accepted') {
    return children;
  }

  // Per altre pagine, controlla se il checkout è completo
  return isCheckoutComplete ? children : <Navigate to="/shipping" replace />;
};