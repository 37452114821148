// theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      // Stili applicati a tutto il body dell'app
      body: {
        bg: 'gray.50',
        color: 'gray.700',
      },
    },
  },
  colors: {
    brand: {
      50: '#e3f2f9',
      100: '#c5e4f3',
      200: '#a2d4ec',
      300: '#7ac1e4',
      400: '#47a9da',
      500: '#0088cc',
      600: '#007ab8',
      700: '#006ba1',
      800: '#005885',
      900: '#003f5e',
    },
  },
  fonts: {
    heading: 'Georgia, serif',
    body: 'Arial, sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold', // Peso del carattere per tutti i bottoni
      },
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
        // Altre dimensioni personalizzate se necessario
      },
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'brand.600' : 'brand.500',
          color: 'white',
          _hover: {
            bg: props.colorMode === 'dark' ? 'brand.700' : 'brand.600',
          },
        }),
        // Altre varianti personalizzate se necessario
      },
    },
    // Personalizza altri componenti se necessario
  },
  // Altre personalizzazioni se necessario
});

export default theme;
