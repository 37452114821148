// data/reviews.js
// Recensioni fittizie
const reviews = [
    {
      id: 1,
      name: 'Fenella Lara',
      content: 'Ho ricevuto il mio prodotto ieri. Servizio clienti eccezionale! Grazie mille!',
      rating: 5,
    },
    {
        id: 2,
        name: 'Marco Tino',
        content: 'Wow. Servizio fantastico.',
        rating: 5,
    },
    // Aggiungi altre recensioni qui
];

export default reviews;