// ReviewCard.js
import React from 'react';
import { Box, Text, VStack, HStack } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

const ReviewCard = ({ review }) => {
    return (
        <VStack
        p={4}
        boxShadow="lg"
        borderRadius="lg"
        bg="white"
        alignItems="center"
        textAlign={"center"}
        minW="sm"
        >
        <Text fontWeight="bold">{review.name}</Text>
        <HStack>
            {Array.from({ length: review.rating }, (_, i) => (
            <StarIcon key={i} color="yellow.400" />
            ))}
        </HStack>
        <Text >{review.content}</Text>
        </VStack>
    );
};

export default ReviewCard;