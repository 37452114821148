import React from 'react';
import { Box, Text, Button, VStack, Image, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Declined() {
  const navigate = useNavigate();

  // Adatta le dimensioni del testo alla larghezza dello schermo
  const titleSize = useBreakpointValue({ base: 'xl', md: '2xl' });
  const bodySize = useBreakpointValue({ base: 'md', lg: 'lg' });

  const handleRetryPayment = () => {
    navigate('/');
  };

  return (
    <VStack spacing={5} align="center" justify="center" p={5}>
      <Image
        src="https://imgs.search.brave.com/UivS9BgbbXlOO1VVdETiuc5opbbT7oMHU4sdQNZXklg/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9naWZk/Yi5jb20vaW1hZ2Vz/L2hpZ2gvc2FkLWFu/ZHJvaWQtY3J5aW5n/LWVtb2ppLTc4eDQ4/aHRjMmFwdWFtd2wu/Z2lm.gif"
        boxSize={useBreakpointValue({ base: '150px', md: '200px' })}
        alt="Pagamento Fallito"
      />
      <Text fontSize={titleSize} fontWeight="bold">
        Pagamento Fallito
      </Text>
      <Text fontSize={bodySize} textAlign="center">
        Siamo spiacenti, ma il tuo pagamento non è andato a buon fine. Per favore, verifica i dati inseriti e prova nuovamente.
      </Text>
      <Button
        colorScheme="red"
        size={useBreakpointValue({ base: 'sm', md: 'md' })}
        onClick={handleRetryPayment}
      >
        Riprova il Pagamento
      </Button>
    </VStack>
  );
}
