import React, { createContext, useContext, useState, useEffect } from 'react';

const PixelContext = createContext();

export const PixelProvider = ({ children }) => {
  const [pixelId, setPixelId] = useState(null);

  useEffect(() => {
    // Recupera il Pixel ID dai parametri URL
    const params = new URLSearchParams(window.location.search);
    const px = params.get('px');
    if (px) setPixelId(px);
  }, []);

  return (
    <PixelContext.Provider value={{ pixelId, setPixelId }}>
      {children}
    </PixelContext.Provider>
  );
};

export const usePixelContext = () => useContext(PixelContext);