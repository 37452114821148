// ShippingMethod.js
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Radio,
  RadioGroup,
  Stack,
  Button,
  Box,
  Text,
  VStack,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import { useCheckoutDispatch, useCheckoutState } from '../context/CheckoutContext';
import { Country, State } from 'country-state-city';
import { useFacebookPixel } from '../hooks/useFacebookPixel';
import { usePixelContext } from '../context/PixelContext'; // Assicurati di aver creato questo contesto

// Schema di validazione aggiornato con Yup
const AddressSchema = Yup.object().shape({
  email: Yup.string().email('Email non valida').required('Richiesto'),
  firstName: Yup.string().required('Richiesto'),
  lastName: Yup.string().required('Richiesto'),
  street: Yup.string().required('Richiesto'),
  city: Yup.string().required('Richiesto'),
  zip: Yup.string().required('Richiesto'),
  country: Yup.string().required('Richiesto'),
  state: Yup.string().required('Richiesto'),
});

export default function ShippingMethod({ setCurrentStep }) {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useCheckoutDispatch();

  const { shippingMethod, cartItems, clickId } = useCheckoutState(); // Aggiungi cartItems qui

  const { pixelId } = usePixelContext();
  const { trackAddPaymentInfo } = useFacebookPixel(pixelId);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const formik = useFormik({
    initialValues: shippingMethod || {
      email: '',
      firstName: '',
      lastName: '',
      street: '',
      company: '',
      apartment: '',
      city: '',
      zip: '',
      country: '',
      state: '',
    },
    validationSchema: AddressSchema,
    onSubmit: async (values) => {

      // Logica di invio dei dati o navigazione
      dispatch({ type: 'SET_SHIPPING_METHOD', payload: values });
      //navigate('/payment');

      // Preparazione dei dati da inviare
      const requestData = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        street: values.street,
        city: values.city,
        zip: values.zip,
        country: values.country,
        state: values.state,
        reference_id: 'cop2024',
        amount: 1000,
        lang: 'ita',
        note: '-',
        clickId: clickId || '' // Aggiungi il clickId qui
      };

      // Prepara le opzioni per la richiesta Fetch
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify(requestData),
      };

      try {

        const response = await fetch('https://check-out-pro-system.com/api/checkout', requestOptions);
        const data = await response.json();

        if (data.success && data.uri) {

          trackAddPaymentInfo();

          // Salva i dettagli dell'ordine in localStorage
          const orderDetails = {
            products: cartItems,
            total: cartItems.reduce((total, item) => total + item.price * item.quantity, 0),
            transactionId: data.transactionId || `ORDER-${Date.now()}` // Usa l'ID transazione fornito dall'API se disponibile
          };
          localStorage.setItem('lastOrderDetails', JSON.stringify(orderDetails));

          console.log("Redirect to payment.");

          dispatch({ type: 'COMPLETE_CHECKOUT' });
          window.location.href = data.uri;
        } else {
          console.error('Request failed.');
        }

      } catch (error) {
        console.error('Request error:', error);
      }

    },

  });

  useEffect(() => {
    if (typeof setCurrentStep === 'function') {
      setCurrentStep(2); // Assumiamo che ShippingMethod sia il secondo passo
    }

    // Rimuovi i dati dal localStorage dopo averli recuperati
    localStorage.removeItem('lastOrderDetails');

    const searchParams = new URLSearchParams(location.search);
    const imageUrl = searchParams.get('imageU');
    const productName = searchParams.get('productN');
    const productPrice = searchParams.get('productP');

    const allCountries = Country.getAllCountries();
    setCountries(allCountries);

    // Ripristino dei dati salvati nel contesto
    if (shippingMethod) {
      formik.setValues(shippingMethod);
      if (shippingMethod.country) {
        const countryStates = State.getStatesOfCountry(shippingMethod.country);
        setStates(countryStates);
      }
    }
  }, [setCurrentStep, shippingMethod]);

  // Assicurati di aggiornare questa parte per gestire lo stato delle province/stati quando il paese cambia
  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    formik.setFieldValue('country', countryCode);
    const countryStates = State.getStatesOfCountry(countryCode);
    setStates(countryStates);
  };

  const shippingOptions = [
    { id: 'standard', name: 'Standard', price: 5.00 },
    { id: 'express', name: 'Express', price: 15.00 },
  ];

  const bg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box borderWidth={1} p={6} borderRadius="md" boxShadow="md" bg={bg} borderColor={borderColor}>
      <Text fontSize={{ base: "lg", md: "2xl" }} mb={4} textAlign="center">Dettagli di spedizione</Text>
      
      <form onSubmit={formik.handleSubmit}>

        {/* Campi dell'indirizzo */}
        <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={3} rowGap={6} mb={6}>

          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormControl isInvalid={formik.touched.email && formik.errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Email" 
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {/* Campo Nome */}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName}>
              <FormLabel>Nome</FormLabel>
              <Input 
                name="firstName" 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                placeholder="Nome" 
              />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {/* Campo Cognome */}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormControl isInvalid={formik.touched.lastName && formik.errors.lastName}>
              <FormLabel htmlFor="lastName">Cognome</FormLabel>
              <Input 
                id="lastName"
                name="lastName" 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                placeholder="Cognome" 
              />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {/* Campo Indirizzo */}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormControl isInvalid={formik.touched.street && formik.errors.street}>
              <FormLabel htmlFor="street">Indirizzo</FormLabel>
              <Input 
                id="street"
                name="street" 
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
                placeholder="Indirizzo" 
              />
              <FormErrorMessage>{formik.errors.street}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {/* Campo Azienda (opzionale) */}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormControl>
              <FormLabel htmlFor="company">Azienda</FormLabel>
              <Input 
                id="company"
                name="company" 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                value={formik.values.company}
                placeholder="Online Shopper (opzionale)" 
              />
            </FormControl>
          </GridItem>

          {/* Campo Appartamento, suite, ecc. (opzionale) */}
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <FormControl>
              <FormLabel htmlFor="apartment">Appartamento, suite, ecc.</FormLabel>
              <Input 
                id="apartment"
                name="apartment" 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                value={formik.values.apartment}
                placeholder="Appartamento, suite, ecc. (opzionale)" 
              />
            </FormControl>
          </GridItem>

          {/* Campo Paese */}
          <GridItem colSpan={2}>
            <FormControl isInvalid={formik.touched.country && formik.errors.country}>
              <FormLabel htmlFor="country">Paese</FormLabel>
              <Select 
                id="country"
                name="country"
                placeholder="Seleziona un paese" 
                onChange={(e) => {
                  formik.handleChange(e);
                  handleCountryChange(e);
                }} 
                onBlur={formik.handleBlur}
                value={formik.values.country}
              >
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>{country.name}</option>
                ))}
              </Select>
              <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {/* Campo Città */}
          <GridItem colSpan={2}>
            <FormControl isInvalid={formik.touched.city && formik.errors.city}>
              <FormLabel htmlFor="city">Città</FormLabel>
              <Input 
                id="city"
                name="city" 
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                value={formik.values.city}
                placeholder="Città" 
              />
              <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
            </FormControl>
          </GridItem>

          {/* Campo CAP e Stato in una riga */}
          <GridItem colSpan={2}>
            <SimpleGrid columns={2} spacing={3}>
              {/* Campo CAP */}
              <FormControl isInvalid={formik.touched.zip && formik.errors.zip}>
                <FormLabel htmlFor="zip">CAP</FormLabel>
                <Input 
                  id="zip"
                  name="zip" 
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.zip}
                  placeholder="CAP" 
                />
                <FormErrorMessage>{formik.errors.zip}</FormErrorMessage>
              </FormControl>

              {/* Campo Stato */}
              <FormControl isInvalid={formik.touched.state && formik.errors.state}>
                <FormLabel htmlFor="state">Stato</FormLabel>
                <Select 
                  id="state"
                  name="state"
                  placeholder="Seleziona uno stato" 
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>{state.name}</option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </GridItem>

        </SimpleGrid>

        {/* 
        <Box mt={6}>
          <RadioGroup onChange={handleSelectShipping} value={selectedShipping}>
            <VStack spacing={4}>
              {shippingOptions.map((option) => (
                <Radio key={option.id} value={option.id} size="lg" name="shippingOption">
                  {option.name} - €{option.price.toFixed(2)}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        </Box>
         */}

        <Button
          mt={6}
          colorScheme="blue"
          type="submit" // Usa type submit per il comportamento di invio del form
          isLoading={formik.isSubmitting}
          isDisabled={!formik.isValid}
          width="full"
        >
          Continua al Pagamento
        </Button>

      </form>

    </Box>
  );
}
