// PaymentMethod.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  VStack,
  HStack,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { useCheckoutDispatch } from '../context/CheckoutContext';
import InputMask from 'react-input-mask';
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { FaCreditCard, FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa';
import moment from 'moment';
import './styles/PaymentMethodStyles.css';

const PaymentSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required('Richiesto')
    .matches(/^[0-9]+$/, "Deve essere solo numerico")
    .min(16, 'Deve avere almeno 16 cifre')
    .max(16, 'Deve avere al massimo 16 cifre'),
  expiryDate: Yup.string()
    .required('Richiesto')
    .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Deve essere nel formato MM/YY"),
  cvv: Yup.string()
    .required('Richiesto')
    .matches(/^[0-9]{3,4}$/, "Deve essere di 3 o 4 cifre"),
});

export default function PaymentMethod({ setCurrentStep }) {
  const navigate = useNavigate();
  const dispatch = useCheckoutDispatch();

  useEffect(() => {
    // Imposta il passo corrente a 1 quando il componente viene montato
    setCurrentStep(2);
  }, [setCurrentStep]);

  const checkExpiryDate = (expiryDate) => {
    const dateParts = expiryDate.split("/");
    const month = parseInt(dateParts[0], 10);
    const year = parseInt(dateParts[1], 10);
 
    const now = moment();
    const expiry = moment(`${year}-${month}`, "YYYY-MM");
 
    return expiry.isBefore(now);
  };

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      p={8}
      bg={useColorModeValue('white', 'gray.700')}
      m={4}
    >
      <Formik
        initialValues={{ cardNumber: '', expiryDate: '', cvv: '' }}
        validationSchema={PaymentSchema}
        onSubmit={(values) => {
          if (checkExpiryDate(values.expiryDate)) {
            alert("La data di scadenza è passata!");
            return;
          }
          dispatch({ type: 'SET_PAYMENT_DETAILS', payload: values });
          navigate('/confirm');
        }}
      >
        {({ errors, touched, getFieldProps }) => (
          <Form>
            <VStack spacing={4}>

            <FormControl isInvalid={errors.cardNumber && touched.cardNumber}>
              <FormLabel htmlFor="cardNumber">Numero di Carta</FormLabel>
              <InputGroup>
                <Input {...getFieldProps('cardNumber')} id="cardNumber" placeholder="Numero di Carta" />
                <InputRightElement>
                  <FaCcVisa size="1.5em" />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.cardNumber}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.expiryDate && touched.expiryDate}>
              <FormLabel htmlFor="expiryDate">Data di Scadenza</FormLabel>
              <InputMask mask="99/99" value={getFieldProps('expiryDate').value} onChange={getFieldProps('expiryDate').onChange}>
                {(inputProps) => <Input {...inputProps} id="expiryDate" placeholder="MM/YY" />}
              </InputMask>
              <FormErrorMessage>{errors.expiryDate}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.cvv && touched.cvv}>
              <FormLabel htmlFor="cvv">CVV</FormLabel>
              <Input {...getFieldProps('cvv')} id="cvv" placeholder="CVV" type="password" />
              <FormErrorMessage>{errors.cvv}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.cardHolderName && touched.cardHolderName}>
              <FormLabel htmlFor="cardHolderName">Titolare della Carta</FormLabel>
              <Input {...getFieldProps('cardHolderName')} id="cardHolderName" placeholder="Titolare della Carta" />
              <FormErrorMessage>{errors.cardHolderName}</FormErrorMessage>
            </FormControl>

            <HStack spacing={4} mt={4}>

              <Button
                colorScheme="dark" // Puoi sostituirlo con un altro colorScheme disponibile
                onClick={() => navigate(-1)}
              >
                Torna Indietro
              </Button>

              <Button
                colorScheme="blue"
                type="submit"
                isLoading={touched && Object.keys(errors).length === 0}
              >
                Conferma Ordine
              </Button>

            </HStack>
              
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
