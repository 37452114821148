// ReviewCarousel.js
import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReviewCard from './ReviewCard';

const ReviewCarousel = ({ reviews }) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentReviewIndex((currentReviewIndex) =>
        currentReviewIndex === reviews.length - 1 ? 0 : currentReviewIndex + 1
      );
    }, 2000); // Cambia recensione ogni 2 secondi

    return () => clearInterval(intervalId); // Pulisci l'intervallo quando il componente si smonta
  }, [currentReviewIndex, reviews.length]);

  return (
    <Box position="relative" marginTop="25px" width="100%">
      <IconButton
        aria-label="Precedente"
        icon={<ChevronLeftIcon />}
        position="absolute"
        left={0}
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
        onClick={() => setCurrentReviewIndex(currentReviewIndex - 1 < 0 ? reviews.length - 1 : currentReviewIndex - 1)}
      />
      <ReviewCard review={reviews[currentReviewIndex]} />
      <IconButton
        aria-label="Successivo"
        icon={<ChevronRightIcon />}
        position="absolute"
        right={0}
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
        onClick={() => setCurrentReviewIndex((currentReviewIndex + 1) % reviews.length)}
      />
    </Box>
  );
};

export default ReviewCarousel;