import React, { createContext, useContext, useReducer, useEffect } from 'react';

const CheckoutStateContext = createContext();
const CheckoutDispatchContext = createContext();

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case 'SET_clickId':
      return { ...state, clickId: action.payload };
    case 'SET_CUSTOMER_INFO':
      return { ...state, customerInfo: action.payload };
    case 'SET_SHIPPING_METHOD':
      return { ...state, shippingMethod: action.payload };
    case 'SET_PAYMENT_DETAILS':
      return { ...state, paymentDetails: action.payload };
    case 'ADD_TO_CART':
      const existingItemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (existingItemIndex > -1) {
        // Se l'articolo esiste già, aumenta solo la quantità
        const updatedCartItems = state.cartItems.map((item, index) => 
          index === existingItemIndex 
            ? { ...item, quantity: item.quantity + (action.payload.quantity || 1) }
            : item
        );
        return { ...state, cartItems: updatedCartItems };
      } else {
        // Se è un nuovo articolo, aggiungilo al carrello
        return { 
          ...state, 
          cartItems: [...state.cartItems, { ...action.payload, quantity: action.payload.quantity || 1 }]
        };
      }
    case 'UPDATE_CART_ITEM_QUANTITY':
      return {
        ...state,
        cartItems: state.cartItems.map(item =>
          item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
        ),
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.id !== action.payload.id),
      };
    case 'CLEAR_CART':
      return { ...state, cartItems: [] };
    case 'COMPLETE_CHECKOUT':
      return { ...state, isCheckoutComplete: true };
    case 'RESET_CHECKOUT':
      return {
        ...state,
        customerInfo: {},
        shippingMethod: {},
        paymentDetails: {},
        cartItems: [],
        isCheckoutComplete: false,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(checkoutReducer, {
    customerInfo: {},
    shippingMethod: {},
    paymentDetails: {},
    cartItems: [],
    isCheckoutComplete: false,
    clickId: null,
  });

  useEffect(() => {
    // Recupera il clickId dai parametri URL
    const params = new URLSearchParams(window.location.search);
    const clickId = params.get('clickId');
    if (clickId) {
      dispatch({ type: 'SET_clickId', payload: clickId });
    }
  }, []);

  return (
    <CheckoutStateContext.Provider value={state}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
};

const useCheckoutState = () => {
  const context = useContext(CheckoutStateContext);
  if (context === undefined) {
    throw new Error('useCheckoutState must be used within a CheckoutProvider');
  }
  return context;
};

const useCheckoutDispatch = () => {
  const context = useContext(CheckoutDispatchContext);
  if (context === undefined) {
    throw new Error('useCheckoutDispatch must be used within a CheckoutProvider');
  }
  return context;
};

export { CheckoutProvider, useCheckoutState, useCheckoutDispatch };